import _extendShallow from "extend-shallow";
import _safeRegex from "safe-regex";
var exports = {};
var extend = _extendShallow;
var safe = _safeRegex;
/**
 * The main export is a function that takes a `pattern` string and an `options` object.
 *
 * ```js
 & var not = require('regex-not');
 & console.log(not('foo'));
 & //=> /^(?:(?!^(?:foo)$).)*$/
 * ```
 *
 * @param {String} `pattern`
 * @param {Object} `options`
 * @return {RegExp} Converts the given `pattern` to a regex using the specified `options`.
 * @api public
 */

function toRegex(pattern, options) {
  return new RegExp(toRegex.create(pattern, options));
}
/**
 * Create a regex-compatible string from the given `pattern` and `options`.
 *
 * ```js
 & var not = require('regex-not');
 & console.log(not.create('foo'));
 & //=> '^(?:(?!^(?:foo)$).)*$'
 * ```
 * @param {String} `pattern`
 * @param {Object} `options`
 * @return {String}
 * @api public
 */


toRegex.create = function (pattern, options) {
  if (typeof pattern !== "string") {
    throw new TypeError("expected a string");
  }

  var opts = extend({}, options);

  if (opts.contains === true) {
    opts.strictNegate = false;
  }

  var open = opts.strictOpen !== false ? "^" : "";
  var close = opts.strictClose !== false ? "$" : "";
  var endChar = opts.endChar ? opts.endChar : "+";
  var str = pattern;

  if (opts.strictNegate === false) {
    str = "(?:(?!(?:" + pattern + ")).)" + endChar;
  } else {
    str = "(?:(?!^(?:" + pattern + ")$).)" + endChar;
  }

  var res = open + str + close;

  if (opts.safe === true && safe(res) === false) {
    throw new Error("potentially unsafe regular expression: " + res);
  }

  return res;
};
/**
 * Expose `toRegex`
 */


exports = toRegex;
export default exports;